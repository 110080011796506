import axios from "axios"
import React, { useContext } from "react"
import { useEffect, useState } from "react"
import { AuthContext } from "../../../contexts/auth-context/auth"
import { useLocation } from "react-router-dom"
import Header from "../../../components/header"

interface NotaryResponseProps {
	occurrenceDate: string
	amount: number
	officeNumber: string
	city: string
	federalUnity: string
}

interface PefinResponseProps {
	occurrenceDate: string
	amount: number
	creditorName: string
}
interface RefinResponseProps {
	occurrenceDate: string
	amount: number
	creditorName: string
}


interface ConsumerData {
	documentNumber: string;
	consumerName: string;
	motherName: string;
	birthDate: string;
	statusRegistration: string;
	statusDate: string;
}

interface ClientStatus {
	nome: string,
	motorDeDecisao: string
	score: number
}

const DetailsReport = () => {
	const [notarys, setNotarys] = useState<NotaryResponseProps[]>([])
	const [pefin, setPefin] = useState<PefinResponseProps[]>([])
	const [refin, setRefin] = useState<RefinResponseProps[]>([])
	const [clientStatus, setClientStatus] = useState<ClientStatus>()

	const formatStringDate = (date: string) => {
		const split = date.split('-')


		return `${split[2]}/${split[1]}/${split[0]}`
	}


	const { auth } = useContext(AuthContext)
	const location = useLocation();

	useEffect(() => {

		const getData = async () => {
			console.log(auth)

			let queryParams = await new URLSearchParams(location.search);
			let cpf = queryParams.get('cpf');
			console.log(cpf)

			const format = cpf?.replaceAll(".", "").replaceAll("-", "")
			axios.get(`https://nycserasaservice.azurewebsites.net/api/v1/consult-serasa/GetFullReportByCpf/${format}`, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			})
				.then((res: any) => {

					setNotarys(res.data.report?.reports[0].negativeData.notary.notaryResponse)
					setPefin(res.data.report?.reports[0].negativeData.pefin.pefinResponse)
					setRefin(res.data.report?.reports[0].negativeData.refin.refinResponse)
				})
			axios.get(`https://nycserasaservice.azurewebsites.net/api/v1/consult-serasa/GetReportByCpf/${format}`, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			})
				.then((res: any) => {
					console.log(res)
					setClientStatus(res.data.consultaResult)
				})
		}
		getData()
	}, [])

	return (
		<>
			<Header />
			<div style={{ margin: '8%', marginTop: '2%' }}>
				<h2 style={{ width: 'auto', fontSize: 28, textTransform: "uppercase" }}>{clientStatus?.nome} - {clientStatus?.motorDeDecisao}</h2>
				<p style={{ color: 'black', fontFamily: 'Poppins Regular' }}><b>Score:</b> {clientStatus?.score}</p>
				<div className="container-serasa">
					<h2>Protestos</h2>
					<div className="grid-card" style={{ color: 'black' }}>
						{notarys.length > 0 ?
							notarys.map((item, index) => (
								<div className="card" key={index}>
									<p>Data da ocorrência: {formatStringDate(item.occurrenceDate)}</p>
									<p>{item.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p>{item.city}</p>
									<p>{item.federalUnity}</p>
								</div>
							)
							) :
							<div>
								<p className="warning card" style={{ color: 'black' }}>Não há protestos</p>
							</div>
						}
					</div>
					<h2>Pefin</h2>
					<div className="grid-card" style={{ color: 'black' }}>
						{pefin.length ?
							pefin.map((item, index) => (
								<div className="card" key={index}>
									<p><b>Data da ocorrência:</b><br></br> {formatStringDate(item.occurrenceDate)}</p>
									<p>{item.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p>{item.creditorName}</p>
								</div>
							))
							:
							<div>
								<p className="warning card" style={{ color: 'black' }}>Não há pendências</p>
							</div>
						}
					</div>
					<h2>Refin</h2>
					<div className="grid-card" style={{ color: 'black' }}>
						{refin.length > 0 ?
							refin.map((item, index) => (
								<div className="card" key={index}>
									<p><b>Data da ocorrência:</b><br></br> {formatStringDate(item.occurrenceDate)}</p>
									<p>{item.creditorName}</p>
									<p>{item.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
								</div>
							))
							:
							<div>
								<p className="warning card" style={{ color: 'black' }}>Não há pendências</p>
							</div>}
					</div>
				</div>
			</div>
		</>
	)
}

export default DetailsReport
import Header from "../../../components/header";
import axios from "axios";
import './style.css'
import Input from "../../../components/inputs/defaultInput";
import Logonyc from '../../../assets/logoNYC/logo_nycbank_black.svg'
import { useContext, useEffect, useState } from "react";
import MaskInput from "../../../components/inputs/maskedInput";
import Select from "../../../components/inputs/selectInput";
import Button from "../../../components/buttons/primaryButton";
import CryptoNyc from '../../../utils/decrypto-nyc'

import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth-context/auth";
import Loading from "../../../components/loading";

export default function RegisterNewLoan() {
	const [date, setDate] = useState('');
	const [cpf, setCPF] = useState('')
	const [valor, setValor] = useState('')
	const [parcelas, setParcelas] = useState('')
	const [loading, setLoading] = useState(true)

	const cpfPerson = typeof window !== 'undefined' ? sessionStorage.getItem('cpfLoan') : null;

	const currentDate = new Date();
	const formatDate = new Date(date);
	const creditDate = currentDate.toLocaleDateString()

	useEffect(() => {
		if (cpfPerson) {
			setCPF(cpfPerson)
		}
	})

	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)


	const generateLoan = () => {
		setLoading(true)
		const cpfFormat = cpf.replaceAll('-', '').replaceAll('.', '')

		axios.post('https://nycbank-loanservice.azurewebsites.net/api/dash/enableperson', {
				"cpf": cpfFormat
			}, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setLoading(true)
				toast.success('O CPF foi permitido!', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light",
				});
				setTimeout(() => {
					setLoading(false)
					navigate("/new-loans")
				}, 5000);
			}).catch((e) => {
				setLoading(true)
				setTimeout(() => {
					toast.error('Algo deu errado, tente novamente', {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						rtl: false,
						pauseOnFocusLoss: true,
						draggable: true,
						pauseOnHover: true,
						theme: "light",
					});
				}, 1000);

			})

	}

	return (
		<>
			<Header />

			{loading ?
				<div className="container">
					{/* <img src={Logonyc.toString()} className="logo" alt="logo da NYC Bank" /> */}

					<form>
						<div className="form-grid">
						<h1>Informe o CPF do tomador</h1>
							<h2>CPF</h2>
							<MaskInput mask="999.999.999-99" maskChar={null} placeholder="xxx.xxx.xxx-xx" type="text" value={cpf} onChange={(e) => setCPF(e.target.value)} />
							<Button type="button" onClick={generateLoan} textButton={'Permitir'} />
						</div>
					</form>
					<ToastContainer transition={Slide} />
				</div >
				:
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	)
}
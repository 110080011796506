import { AuthContext } from '../../contexts/auth-context/auth';
import { FlagContext } from '../../contexts/flag-context/flag';

import './style.css';
import React, { useContext, useState } from 'react'
import logonyc from "../../assets/logoNYC/logo_nycbank_black.svg"
import { SignIn } from '../../utils/SignIn';
import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MaskInput from '../../components/inputs/maskedInput';
import Input from '../../components/inputs/defaultInput';
import IconButton from '../../components/buttons/iconButton';
import Button from '../../components/buttons/primaryButton';
import Hide from '../../assets/icons/hide.svg'
import Show from '../../assets/icons/show.svg'
import { Link, useNavigate } from "react-router-dom";
import Loading from '../../components/loading';

export interface Flag {
	isFinancial: boolean,
	isCommercial: boolean,
	isAdministrative: boolean,
	isCorrespondenBbanking: boolean,
	isAttendant: boolean
}


export default function SignInPage() {
	const [cpf, setCPF] = useState("")
	const [userPassword, setSenhaUser] = useState("")
	const [passwordVisbility, setpasswordVisbility] = useState("password")
	const [loading, setLoading] = useState(false)
	const [inputValue, setInputValue] = useState('');

	const { auth, setAuth } = useContext(AuthContext)
	const { flag, setFlag } = useContext(FlagContext)

	const navigate = useNavigate();
	const showOrHide = () => {

		if (passwordVisbility == "tel") {
			setpasswordVisbility("password")
		} else {
			setpasswordVisbility("tel")
		}
	}

	const setSignIn = async () => {
		const req = await SignIn(cpf, userPassword);


		// Log the status
		switch (req?.status) {
			case 202:
				// sessionStorage.setItem('token', req.authToken)
				setFlag(req.flag.result.userFlags)
				setAuth(req.authToken)
				sessionStorage.setItem('cpfLogin', cpf)
				setLoading(true)
				setTimeout(() => {
					if (req.flag.result.userFlags.isAdministrative) {
						// console.log('oi')
						navigate('/report')
					}
					else if (req.flag.result.userFlags.isCorrespondenBbanking) {
						// console.log('oi')
						navigate('/attendants')
					}
					else if (req.flag.result.userFlags.isCommercial) {
						// console.log('oi')
						navigate('/prospect')
					}
					else if (req.flag.result.userFlags.isFinancial) {
						// console.log('oi')
						navigate('/loans')
					}
					else if (req.flag.result.userFlags.isAttendant) {
						// console.log('oi')
						navigate('/clients')
					} else {
						// // console.log('oi')
						navigate('/report')
					}
				}, 2000);

				break;
			case 401:
				toast.error('Senha incorreta, tente novamente', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				});
				break;
			case 400:
				toast.error('CPF não encontrado, verifique se o número está correto', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				});
				break;
		}
	}

	const handleKeyPress = (event: any) => {
		if (event.key === 'Enter') {
			// Handle the Enter key press here
			setSignIn()
			// You can perform any action or call a function here
		}
	};

	return (
		// {}
		<>
			<div className="SignUpContainer">
				<img src={logonyc.toString()} alt="logo da NYC Bank" />

				<div className="SignUp">
					<MaskInput mask="999.999.999-99" maskChar={null} placeholder="CPF" type="text" value={cpf} onChange={(e) => setCPF(e.target.value)} />
					<div className="passwordContainer">
						<Input
							maxLength={6}
							placeholder="Senha"
							type={passwordVisbility}
							onKeyDown={handleKeyPress}
							value={userPassword}
							onChange={(e) => {
								setSenhaUser(e.target.value);
								setInputValue(e.target.value);
							}} />
						<IconButton onClick={() => showOrHide()} alt="EsconderSenha" icon={passwordVisbility == "tel" ? Show.toString() : Hide.toString()} />
					</div>
					<Button type="button" className="Button" onClick={setSignIn} textButton={"Enviar"} />
				</div>
				<ToastContainer transition={Slide} />
				{!loading ?
					<>
					</>
					:
					<div className="loading-page-loan login">
						{/* <p>Carregando...</p> */}
						<Loading />
					</div>
				}
			</div>


		</>
	);
}


import './style.css'
import React, { useContext, useEffect, useState } from 'react'
import Logo from '../../assets/logoNYC/logo_nycbank_white.svg'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth-context/auth'
import { FlagContext } from '../../contexts/flag-context/flag'

export default function Header() {
	const { auth, setAuth } = useContext(AuthContext)
	const { flag, setFlag } = useContext(FlagContext)
	const [permission, setPermission] = useState(1)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [showMenu, setShowMenu] = useState(false)



	const Logout = () => {
		setAuth('')
	}
	useEffect(() => {
		// console.log(flag)
		if (flag?.isAttendant) {
			setPermission(5)
		}
		if (flag?.isCorrespondenBbanking) {
			setPermission(4)
		}
		if (flag?.isCommercial) {
			setPermission(3)
		}
		if (flag?.isFinancial) {
			setPermission(2)
		}
		if (flag?.isAdministrative) {
			setPermission(1)
		}
	}, [])
	return (
		<header>
			<img
				src={Logo.toString()}
				alt="Logo Padrão"
				width={150}
			/>
			{
				windowWidth < 1200 ?

					<div style={{ position: 'relative' }}>
						<div onClick={() => !showMenu ? setShowMenu(true) : setShowMenu(false)} style={{ display: 'grid', gap: 3 }}>
							<span style={{ display: 'flex', width: 24, height: 3, borderRadius: 10, backgroundColor: 'white' }}></span>
							<span style={{ display: 'flex', width: 24, height: 3, borderRadius: 10, backgroundColor: 'white' }}></span>
							<span style={{ display: 'flex', width: 24, height: 3, borderRadius: 10, backgroundColor: 'white' }}></span>
						</div>


						{
							showMenu &&
							<nav style={{ display: 'grid', position: 'absolute', backgroundColor: 'white', color: 'black', right: -3, borderRadius: 16, padding: '8px 8px', boxShadow: '#0000005e 0px 1px 2px', top: 24 }}>
								{
									permission == 1 &&
									<>

										<Link to="/consult-cpf">Consulta</Link>
										<Link to="/cash-out">Saque</Link>
										<Link to="/update-status">Roles</Link>
										<Link to="/users">Correntistas</Link>
										<Link to="/prospect">Prospecção</Link>
										<Link to="/extract">Extrato</Link>
										<Link to="/dashboard">Dashboard</Link>
										<Link to="/report">Cobranças</Link>
										<Link to="/loans">Empréstimos</Link>
										<Link to="/new-loans">Novo Emprést.</Link>
									</>
								}
								{
									permission == 2 &&
									<>
										<Link to="/cash-out">Saque</Link>
										<Link to="/extract">Extrato</Link>
										<Link to="/dashboard">Dashboard</Link>
										<Link to="/report">Cobranças</Link>
										<Link to="/prospect">Prospecção</Link>
										<Link to="/loans">Empréstimos</Link>
									</>
								}
								{
									permission == 3 &&
									<>
										<Link to="/correspondents">Meus Correspondentes</Link>
										<Link to="/prospect">Prospecção</Link>
										<Link to="/loans">Empréstimos</Link>
									</>
								}
								{
									permission == 4 &&
									<Link to="/attendants">Meus Atendentes</Link>
								}
								{
									permission == 5 &&
									<Link to="/clients">Meus Clientes</Link>
								}
								<Link to="/signin" onClick={Logout}>Logout</Link>
							</nav >
						}

					</div>
					:
					<nav>

						{
							permission == 1 &&
							<>
								<Link to="/signup">Cadastro</Link>
								<Link to="/consult-cpf">Consulta</Link>
								<Link to="/cash-out">Saque</Link>
								<Link to="/update-status">Roles</Link>
								<Link to="/users">Correntistas</Link>
								<Link to="/prospect">Prospecção</Link>
								<Link to="/extract">Extrato</Link>
								<Link to="/dashboard">Dashboard</Link>
								<Link to="/report">Cobranças</Link>
								<Link to="/loans">Empréstimos</Link>
								<Link to="/new-loans">Novo Emprést.</Link>

							</>
						}
						{
							permission == 2 &&
							<>
								<Link to="/cash-out">Saque</Link>
								<Link to="/extract">Extrato</Link>
								<Link to="/dashboard">Dashboard</Link>
								<Link to="/report">Cobranças</Link>
								<Link to="/prospect">Prospecção</Link>
								<Link to="/loans">Empréstimos</Link>
							</>
						}
						{
							permission == 3 &&
							<>
								<Link to="/consult-cpf">Consulta</Link>
								<Link to="/users">Correntistas</Link>
								<Link to="/correspondents">Meus Correspondentes</Link>
								<Link to="/prospect">Prospecção</Link>
								<Link to="/loans">Empréstimos</Link>
							</>
						}
						{
							permission == 4 &&
							<Link to="/attendants">Meus Atendentes</Link>
						}
						{
							permission == 5 &&
							<Link to="/clients">Meus Clientes</Link>
						}
						<Link to="/signin" onClick={Logout}>Logout</Link>
					</nav >
			}

		</header >
	)
}
import React from 'react';
import './style.css'

type Select =
	{
		onInput?: (a: any) => void;
		onChange?: (a: any) => void;
		onBlur?: (a: any) => void;
		onKeyDown?: (a: any) => void;
		placeholder?: string
		type?: string
		value?: any
		className?: string
		maxLength?: number | undefined
		name?: string
	}

export default function Input(props: Select) {
	const { onInput, onKeyDown, onChange, onBlur, placeholder, type, value, className, maxLength, name } = props
	return (
		<input onChange={onChange} placeholder={placeholder} onInput={onInput} onKeyDown={onKeyDown} name={name} maxLength={maxLength} type={type} value={value} onBlur={onBlur} className={`${className} input`} />
	)
}
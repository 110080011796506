import React, { Consumer, useContext, useEffect, useState } from "react";
import Header from "../../components/header";
import axios from "axios";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/auth-context/auth";
import './style.css'
import { Link } from "react-router-dom";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../../components/loading";
import Button from "../../components/buttons/primaryButton";
import ButtonIcon from "../../components/buttons/iconButton";
import barCode from "../../assets/icons/bar-code.svg";
import doc from "../../assets/icons/document-svgrepo-com-nyc.svg";
import { checkLoans } from "../../utils/check";
import { toDimension } from "chart.js/dist/helpers/helpers.core";

interface Customer {
	cpf: string;
	id: number;
	role: string;
	status: string;
}

interface CustomerProposal {
	customer: any;
	customerID: number;
	controlId: string;
	proposalId: string | null;
	situation: string | null;
	id: number;
	createdAt: string;
	updatedAt: string | null;
	deletedAt: string | null;
}

export default function RegisteredNewPerson() {
	const navigate = useNavigate();

	const { auth, setAuth } = useContext(AuthContext);
	useEffect(() => {

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	})

	const [data, setData] = useState<Customer[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		console.log(auth)
		axios.get('https://nycbank-loanservice.azurewebsites.net/api/Dash/GetAll', {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			// console.log(res.data);
			// setData(res.data);
			let a:Customer[] = [];
			res.data.forEach((_data:Customer) => {
				switch (_data.role) {
					case 'needcreatephone':
						_data.status = 'Aguardando inicio do cadastro';
					break;
					case 'needvalidatephone':
						_data.status = 'Aguardando finalização do cadastro';
					break;
					case 'needpersonalinfo':
						_data.status = 'Aguardando finalização do cadastro';
					break;
					case 'needuploaddocument':
						_data.status = 'Aguardando upload do documento';
					break;
					case 'waitingsignature':
						_data.status = 'Aguardando assinatura do contrato';
					break;
					case 'needemail' :
						_data.status = 'Aguardando finalização do cadastro';
					break;
					case 'needaddress':
						_data.status = 'Aguardando finalização do cadastro';
					break;
					case 'needbankdata':
						_data.status = 'Aguardando finalização do cadastro';
					break;
					case 'waitingvalidation':
						_data.status = 'Aguardando emissão do empréstimo';
					break;
					case 'validated':
						_data.status = 'Contrato assinado com sucesso';
					break;
				}
				a.push(_data);
			});
			setData(a);
			setLoading(false);
		});
			// setLoading(false);

	}, []);

	const finishLoan = (cpf: string) => {
		localStorage.setItem("finish-loan-cpf", cpf);
		navigate("/new-loans/finish-loan")
	}

	return (
		<>
			<Header />
			{!loading ?
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<nav className="navContainer">
						{/* <Link to="/loans/register-cpf">Cadastrar Cliente</Link> */}
						<Button onClick={() => navigate("/new-loans/register-loan")} textButton="Permitir Novo Empréstimo" />
					</nav>
					<div className="tableContainer">
						<table className="table">
							<thead className="tableHeader">
								<tr>
									<th style={{textAlign: "center"}}>CPF</th>
									<th style={{textAlign: "center"}}>Status de Proposta</th>
									<th style={{textAlign: "center"}}>Ações</th>
								</tr>
							</thead>
							<tbody className="tableBody">
								{data.length == 0 ? <>
									<tr id='linhas' className='lightGreen'>
											<td colSpan={3} style={{textAlign: "center"}}>Não possui cadastros</td>
										</tr>
										</>:<></>}
								{data.map((item, index) => (
									<tr key={index}>
									<td style={{textAlign: "center"}}>{item.cpf}</td>
									<td style={{textAlign: "center"}}>{item.status}</td>
									{item.role == 'waitingvalidation' ? <td className="link" style={{textAlign: "center", color: 'var(--nyc-green-400)', textDecoration: 'underline'}} onClick={() => finishLoan(item.cpf)}>Gerar Contrato CCB</td> : <td>-</td>}
								</tr>

								))}
							</tbody>
						</table>
					</div>
					<ToastContainer transition={Slide} />
				</div>
				:
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	);
}
